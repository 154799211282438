<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <v-card>
      <v-sheet color="secondary lighten-1">
        <v-card-title>{{$t('m.account.email.title')}}</v-card-title>
      </v-sheet>
      <v-alert tile type="error" v-if="errorMessage">{{errorMessage}}</v-alert>
      <v-card-text>
        <ValidationProvider
          :name="$t('m.account.email.email')"
          rules="required|email"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="email"
            :label="$t('m.account.email.email')"
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('m.account.email.password')"
          rules="required|min:6"
          v-slot="{ errors }"
          vid="password"
        >
          <v-text-field
            type="password"
            :error-messages="errors"
            v-model="password"
            :label="$t('m.account.email.password')"
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('m.account.email.password2')"
          rules="required|confirmed:password"
          v-slot="{ errors }"
        >
          <v-text-field
            type="password"
            :error-messages="errors"
            v-model="passwordVerify"
            :label="$t('m.account.email.password2')"
          />
        </ValidationProvider>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="linking" text @click="$emit('close')">{{$t('m.cancel')}}</v-btn>
        <v-btn :disabled="invalid" :loading="linking" @click="onLink">{{$t('m.link')}}</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import errorLookups from './errors'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      email: null,
      password: null,
      passwordVerify: null,
      linking: false,
      rawErrorCode: null
    }
  },
  computed: {
    errorMessage () {
      const lookupResult = errorLookups[this.rawErrorCode]
      return lookupResult || this.rawErrorCode
    }
  },
  methods: {
    ...mapActions('auth', ['linkEmail']),
    async onLink () {
      this.rawErrorCode = null
      this.linking = true
      try {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.linkEmail({ email: this.email, password: this.password })
          this.$emit('close')
        }
      } catch (err) {
        console.error('error linking email address', err) // eslint-disable-line no-console
        // this.$emit('error', err.code)
        this.rawErrorCode = err.code
      } finally {
        this.linking = false
      }
    }
  }
}
</script>

<style>
</style>
